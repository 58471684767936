import React, { Component } from 'react';

class Terms extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            //    Work process Start
            <section className="work-process section-padding">
                <div className="container">
                    <div className="row mb-10">
                        <div className="">
                            <div className="section-heading text-center">
                                <p><strong>Effective Date:</strong> 1st September 2024</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="privacy-doc">

                            <p>Welcome to BUGnBULL! These Terms of Service ("Terms") govern your use of our website and services. By accessing or using the website, you agree to be bound by these Terms. Please read them carefully.</p>

                            <h4>1. Acceptance of Terms</h4>
                            <p>    By using this website, you accept these Terms in full. If you disagree with these Terms or any part of these Terms, you must not use this website.</p>

                            <h4>2. Services Provided</h4>
                            <p>    We offer educational courses and resources related to trading, web development, and cybersecurity. Any new features or tools added to the current website shall also be subject to the Terms.</p>

                            <h4>3. Partnership Firm</h4>
                            <p>    Our institute is operated as a partnership firm, and all services provided are delivered in partnership between Punit Jain, Tanishk Shamra, and Ujjwal Natani.</p>

                            <h4>4. Eligibility</h4>
                            <p>    To use our services, you must be at least 18 years of age and fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms.</p>

                            <h4>5. User Accounts</h4>
                            <p>    You may be required to create an account to access certain features of our website. You agree to provide accurate, complete, and current information and to keep this information up to date.</p>

                            <h4>6. Fees and Payments</h4>
                            <p>    Some of our courses may require payment. All fees are listed on the website and are subject to change without notice. Payments must be made before accessing paid content or courses. We are not responsible for any transaction fees or delays caused by payment processors.</p>

                            <h4>7. Refund Policy</h4>
                            <p>    Refunds for courses or services will only be offered in accordance with our refund policy, which can be found <a href="/return-policy">here</a>.</p>

                            <h4>8. Intellectual Property</h4>
                            <p>    All content on this website, including but not limited to course materials, videos, text, graphics, and logos, is the intellectual property of BUGnBULL or our licensors. You may not reproduce, distribute, or create derivative works from our content without express written permission.</p>

                            <h4>9. User Conduct</h4>
                            <p>    You agree not to use the website or our services for any unlawful purpose or in any manner that could damage, disable, overburden, or impair the site or interfere with any other user’s use and enjoyment of the site.</p>

                            <h4>10. Termination</h4>
                            <p>    We reserve the right to suspend or terminate your access to the website or our services at any time, without notice, for any violation of these Terms or for any reason we deem appropriate.</p>

                            <h4>11. Third-Party Links</h4>
                            <p>    Our website may contain links to third-party websites or services that are not owned or controlled by BUGnBULL. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.</p>

                            <h4>12. Limitation of Liability</h4>
                            <p>    In no event shall BUGnBULL, its partners, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use our services.</p>

                            <h4>13. Disclaimer</h4>
                            <p>    Our services and website are provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the accuracy or reliability of any information provided on our site or through our services.</p>

                            {/* <h4>14. Governing Law</h4>
                                <p>These Terms shall be governed by and construed in accordance with the laws of [Insert Country/State]. Any disputes relating to these Terms shall be subject to the exclusive jurisdiction of the courts in [Insert City].</p> */}

                            <h4>14. Changes to the Terms</h4>
                            <p>    We reserve the right to modify these Terms at any time. Any changes will be posted on this page, and your continued use of the website following such changes constitutes your acceptance of the new Terms.</p>

                            <h4>15. Contact Us</h4>
                            <p>    If you have any questions about these Terms, please contact us at:<br />
                                Email: bugndbull@gmail.com<br />
                                Phone: +918560958895<br />
                            </p>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Terms;
