import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AboutComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null,
            process: [
                {
                    id: 1,
                    step: '01',
                    title: 'Visit Us',
                    text: 'Visit our campus to explore facilities, meet our team, and learn about our programs firsthand. Let us help you achieve your educational goals.',
                },
                {
                    id: 2,
                    step: '02',
                    title: 'Complete Your Application',
                    text: 'Fill out the form with your personal and educational details. This helps us tailor our programs to your needs.',
                },
                {
                    id: 3,
                    step: '03',
                    title: 'Enroll in Your Chosen Course',
                    text: 'After reviewing your application, proceed with enrollment. Our team will assist with payment and course confirmation.',
                },
                {
                    id: 4,
                    step: '04',
                    title: 'Get certified',
                    text: 'Complete your course to receive a prestigious certificate, enhancing your credentials and unlocking new career paths.',
                }
            ],
            windowWidth: window.innerWidth
        }
    }

    handleHover = (index) => {
        this.setState({ activeIndex: index });
    }

    handleClick = (index) => {
        if (this.state.activeIndex === index) {
            this.setState({ activeIndex: null });
        } else {
            this.setState({ activeIndex: index });
        }
    }


    updateWindowWidth = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    render() {

        const { windowWidth } = this.state;

        return (
            //    Work process Start
            <section className="work-process section-padding">
                <div className="container">
                    {/* <div className="row mb-70 justify-content-between">
                        <div className="col-xl-5 col-lg-6">
                            <div className="section-heading mb-4 mb-xl-0">
                                <span className="subheading">How to Start</span>
                                <h2 className="font-lg">Start your journey with us</h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <p>We'll help you define your goals and create a roadmap to a life filled with purpose and passion.</p>
                        </div>
                    </div> */}

                    <div className="row mb-10">
                        <div className="">
                            <div className="section-heading text-center">
                                <h2 className="font-lg">Start your journey With us</h2>
                                <p>We'll help you define your goals and create a roadmap to a life filled with purpose and passion.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-xl-7 pe-xl-5 col-lg-12">
                            {/* <div className="row">
                            {
                                this.state.process.map((data, i) => (
                                    <div key={data.id}>
                                        <div
                                            className={`step-item ${this.state.activeIndex === i ? 'active' : ''}`}
                                        onMouseEnter={() => this.handleHover(i)}
                                        onMouseLeave={() => this.handleHover(null)}
                                        >
                                            <div className="step-text">
                                                <h5><i className={this.state.activeIndex === i ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClick(i)}> {data.title}</i></h5>
                                                <p>{this.state.activeIndex === i ? data.text : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </div> */}

                            {windowWidth <= 500 ? (
                                <div>
                                    <img src='assets/images/about/img_2.png' alt="" className="about-img-2" style={{marginBottom:'-60px'}}/>
                                </div>
                            ) : (
                                <div>
                                    <img src='assets/images/about/img_1.png' alt="" className="about-img-2" />
                                </div>
                            )}

                        </div>

                        {/* <div className="col-xl-5">
                            <div className="video-section mt-3 mt-xl-0">
                                <div className="video-content">
                                    <img src="assets/images/bg/office01.jpg" alt="" className="img-fluid" />
                                    <Link to="#" className="video-icon video-popup"><i className="fa fa-play"></i></Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        );
    }
}

export default AboutComp;
