import './App.css';
import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import HomeOne from './components/pages/Home01';
import HomeTwo from './components/pages/Home02';
import AboutMain from './components/pages/About';
import CourseOne from './components/pages/CourseOne';
import CourseTwo from './components/pages/CourseTwo';
import CourseThree from './components/pages/CourseThree';
import CourseFour from './components/pages/CourseFour';
import CourseList from './components/pages/CourseList';
import Courses from './components/pages/Courses';
import Cybersecurity from './components/pages/CyberSecurity';
import WebDevelopment from './components/pages/WebDevelopment';
import Stockmarket from './components/pages/StockMarket';

import CourseDetails from './components/pages/CourseDetails';
import CourseDetailsTwo from './components/pages/CourseDetailsTwo';

import InstructorOne from './components/pages/InstructorPage';


import Login from './components/pages/Login';
import Register from './components/pages/Register';

import ContactPage from './components/pages/ContactPage';
import BlogPageOne from './components/pages/BlogPageOne';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import ReturnPolicy from './components/pages/ReturnPolicy';
import TermsOfService from './components/pages/TermsOfService';

import Layout from './components/layouts/layout';
import ScrollToTop from './common/ScrollToTop';

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path={`${process.env.PUBLIC_URL + '/'}`} element={<HomeOne />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/home-2'}`} element={<HomeTwo />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/about'}`} element={<AboutMain />} />

          <Route exact path={`${process.env.PUBLIC_URL + '/course-1'}`} element={<CourseOne />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/course-2'}`} element={<CourseTwo />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/course-3'}`} element={<CourseThree />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/course-4'}`} element={<CourseFour />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/course-list'}`} element={<CourseList />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/courses'}`} element={<Courses />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/cyber-security'}`} element={<Cybersecurity />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/web-development'}`} element={<WebDevelopment />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/stock-market'}`} element={<Stockmarket />} />

          <Route exact path={`${process.env.PUBLIC_URL + '/course-details/:id'}`} element={<CourseDetails />} />

          <Route exact path={`${process.env.PUBLIC_URL + '/course-details-two/:id'}`} element={<CourseDetailsTwo />} />

          <Route exact path={`${process.env.PUBLIC_URL + '/instructors'}`} element={<InstructorOne />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} element={<Login />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/register'}`} element={<Register />} />

          <Route exact path={`${process.env.PUBLIC_URL + '/contact'}`} element={<ContactPage />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<BlogPageOne />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/return-policy'}`} element={<ReturnPolicy />} />
          <Route exact path={`${process.env.PUBLIC_URL + '/terms-of-service'}`} element={<TermsOfService />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

export default App;

