import React from 'react';
import { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Nav from './Nav';

const HeaderOne = ({ onCoursesClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const closeIconRef = useRef(null);
    const navigate = useNavigate();

    const openMenu = (event) => {
        event.preventDefault(); // Prevent default link behavior
        setIsMenuOpen(true);
    };
    const closeMenu = (event) => {
        event.preventDefault();
        setIsMenuOpen(false);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the menu and close icon
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                closeIconRef.current &&
                !closeIconRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const navigateToCourses = (domain) => {
        navigate('/courses', { state: { domain } });
    };

    return (
        <>
            <header className="header-style-1" style={{ backgroundColor: '#CADFDF' }}>
                <div className={`header-navbar nav fixed-top ${windowWidth <= 501 ? 'mobile-menu' : ''}`} >
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between" style={{marginBlock:'-15px'}}>
                            <div>
                                <Link to="/" className="site-logo">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="Course Thumb" class="img-fluid" />
                                    <h2>BUGnBULL</h2>
                                </Link>
                            </div>
                            <div>
                                <div className="offcanvas-icon d-block d-lg-none">
                                    <a href="/" onClick={openMenu} className="nav-toggler">
                                        <i className="fal fa-bars"></i>
                                    </a>
                                </div>
                            </div>

                            <nav ref={menuRef} className={`site-navbar ms-auto ${isMenuOpen ? 'menu-on' : ''}`} >
                                <Nav onCoursesClick={onCoursesClick} />

                                 {isMenuOpen && (
                                    <a
                                        ref={closeIconRef}
                                        href="/"
                                        onClick={closeMenu}
                                        className="nav-close offcanvas-menu"
                                    >
                                        <i className="fal fa-times"></i>
                                    </a>
                                )}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderOne;