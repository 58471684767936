import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Nav = ({ onCoursesClick }) => {

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const spanElement = document.createElement('span');
      spanElement.className = 'menu-trigger';
      spanElement.innerHTML = '<i class="fal fa-angle-down"></i>';
      containerRef.current.appendChild(spanElement);
    }
  }, []);

  const [menuItems, setMenuItems] = useState([
    {
      label: 'Home',
      link: '/'
    },
    {
      label: 'About',
      link: '/about'
    },
    {
      label: 'Categories',
      submenu: [
        { label: 'Cyber Security', link: '/cyber-security' },
        { label: 'Stock Market', link: '/stock-market' },
        { label: 'Web Development', link: '/web-development' }
      ]

    },
    {
      label: 'Blog',
      link: '/blog'
    },
    {
      label: 'Contact',
      link: '/contact'
    },
  ]);

  const handleSubmenuToggle = (index) => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item, i) =>
        i === index ? { ...item, submenuOpen: !item.submenuOpen } : item
      )
    );
  };

  return (
    <ul className="primary-menu">
      {menuItems.map((item, index) => (
        <li key={index}
        >
          <Link to={item.link} onClick={item.onClick}>
            {item.label}
          </Link>

          {item.submenu && (
            <>
              <span
                className={`menu-trigger ${item.submenuOpen ? 'open' : ''}`}
                onClick={() => handleSubmenuToggle(index)}
              >
                <i
                  className={`submenu-icon ${item.submenuOpen ? 'fal fa-angle-up' : 'fal fa-angle-down'
                    }`}
                ></i>
              </span>
              <ul className={`submenu ${item.submenuOpen ? 'open' : ''}`}>
                {item.submenu.map((subitem, subindex) => (
                  <li key={subindex}>
                    <Link to={subitem.link}>{subitem.label}</Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Nav;
