import React, { useRef } from 'react';

import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';

import { Banner, Counter, CourseGrid01, CourseCat01, About, CourseGridPopular, Features, Team, Review, Cta } from '../layouts/home01/index';


const HomeOne = () => {
    const courseGridRef = useRef(null);
    const courseCatRef = useRef(null);

    // Function to scroll smoothly to an element
  const scrollToElement = (ref) => {
    // Check if the ref is available
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };


    // const scrollToCourses = () => {
    //     if (courseCatRef.current) {
    //         courseCatRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    // const scrollToTrendingCourses = () => {
    //     if (courseGridRef.current) {
    //         courseGridRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return (
        <div className="home-page">
            {/* <HeaderOne onCoursesClick={scrollToCourses} />
            <Banner onTrendingCoursesClick={scrollToTrendingCourses} /> */}
             <HeaderOne onCoursesClick={() => scrollToElement(courseCatRef)} />
             <Banner onTrendingCoursesClick={() => scrollToElement(courseGridRef)} />
            <Counter />
            <div ref={courseGridRef}>
                <CourseGrid01 />
            </div>
            <div ref={courseCatRef}>
                <CourseCat01 />
            </div>
            <About />
            {/* <CourseGridPopular /> */}
            <Features />
            <Team />
            {/* <Review /> */}
            <Cta />
            {/* <FooterOne /> */}
        </div>
    )
}

export default HomeOne;