import React, { Component } from 'react';

class Policy extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            //    Work process Start
            <section className="work-process section-padding">
                <div className="container">

                    <div className="row mb-10">
                        <div className="">
                            <div className="section-heading text-center">
                                <p><strong>Effective Date:</strong> 1st September 2024</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="privacy-doc">

                            <p>Welcome to BUGnBULL. We are committed to protecting your privacy and ensuring your personal data is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website or use our services.</p>

                            <h4>1. Information We Collect</h4>
                            <p>We collect information that you provide to us directly and automatically when you use our services:</p>
                            <ul className="disc-list">
                                <li><strong>Personal Information:</strong> When you register for an account, enroll in courses, or contact us, we may collect personal information such as your name, email address, phone number, payment information, and other information you provide.</li>
                                <li><strong>Usage Data:</strong> We collect information about your interactions with our website, including your IP address, browser type, device information, pages visited, and the time and date of your visit.</li>
                                <li><strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to enhance your experience on our website, understand how our website is used, and for advertising purposes.</li>
                            </ul>

                            <h4>2. How We Use Your Information</h4>
                            <p>We use your information to:</p>
                            <ul className="disc-list">
                                <li>Provide and improve our services, including delivering course content and managing user accounts.</li>
                                <li>Process payments and send transaction-related information.</li>
                                <li>Communicate with you, including sending updates, newsletters, and promotional materials.</li>
                                <li>Understand and analyze usage patterns to improve our website and services.</li>
                                <li>Comply with legal obligations and protect our rights and interests.</li>
                            </ul>

                            <h4>3. Sharing Your Information</h4>
                            <p>We do not sell your personal information. However, we may share your information with:</p>
                            <ul className="disc-list">
                                <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</li>
                                <li><strong>Legal Compliance:</strong> We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests.</li>
                                <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that business transaction.</li>
                            </ul>

                            <h4>4. Your Rights and Choices</h4>
                            <p>Depending on your location and applicable laws, you may have certain rights regarding your personal information:</p>
                            <ul className="disc-list">
                                <li><strong>Access and Update:</strong> You can access and update your personal information by logging into your account or contacting us.</li>
                                <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain exceptions.</li>
                                <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications or by contacting us directly.</li>
                                <li><strong>Cookies:</strong> You can manage your cookie preferences through your browser settings.</li>
                            </ul>

                            <h4>5. Data Security</h4>
                            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

                            <h4>6. Children’s Privacy</h4>
                            <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us, and we will take appropriate action.</p>

                            <h4>7. International Transfers</h4>
                            <p>Your information may be transferred to and processed in countries other than your country of residence. These countries may have data protection laws different from those in your country. We take steps to ensure that your personal information receives an adequate level of protection.</p>

                            <h4>8. Changes to This Privacy Policy</h4>
                            <p>We may update this Privacy Policy from time to time. The updated version will be indicated by an updated “Effective Date” at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about our information practices.</p>

                            <h4>9. Contact Us</h4>
                            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                            <br />

                            <p><strong>BUGnBULL</strong><br />
                                Email: bugndbull@gmail.com<br />
                                {/* Address: [Your Address] */}
                            </p>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Policy;
