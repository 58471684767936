import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const HeaderTwo = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const openMenu = (event) => {
        event.preventDefault();
        setIsMenuOpen(true);
    };
    const closeMenu = (event) => {
        event.preventDefault();
        setIsMenuOpen(false);
    };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <header className="header-style-1" style={{ backgroundColor: '#CADFDF' }}>
                {/* <div className="header-topbar topbar-style-1">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xl-8 col-lg-8 col-sm-6">
                                <div className="header-contact text-center text-sm-start text-lg-start">
                                    <Link to="#">Jaipur, Rajasthan, 302012</Link>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="header-socials text-center text-lg-end">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><Link to="https://www.facebook.com/share/A5VjWW9rTmcXYYey/?mibextid=qi2Omg" target='blank'><i className="fab fa-facebook-f"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.instagram.com/invites/contact/?igsh=1c6g5qh2xuzr8&utm_content=utkdnsl" target='blank'><i className="fab fa-instagram"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://youtube.com/@bugnbull?si=ml0hbVvO0UhK00bN" target='blank'><i className="fab fa-youtube"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={`header-navbar nav fixed-top ${windowWidth <= 501 ? 'mobile-menu' : ''}`} >
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between" style={{marginBlock:'-15px'}}>
                            <div>
                                <Link to="/" className="site-logo">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="Course Thumb" class="img-fluid" />
                                    <h2>BUGnBULL</h2>
                                </Link>
                            </div>

                            <div className="offcanvas-icon d-block d-lg-none">
                                <a href="/" onClick={openMenu} className="nav-toggler"><i className="fal fa-bars"></i></a>
                            </div>

                            {/* <div className="header-category-menu d-none d-xl-block">
                                <ul>
                                    <li className="has-submenu">
                                        <Link to="#"><i className="fa-solid fa-list me-2"></i>Categories</Link>
                                        <ul className="submenu">
                                            <li><Link to="/cyber-security">Cyber Security</Link></li>
                                            <li><Link to="/stock-market">Stock Market</Link></li>
                                            <li><Link to="/web-development">Web Developemnt</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}

                            <nav className={`site-navbar ms-auto ${isMenuOpen ? 'menu-on' : ''}`}>
                                <Nav />

                                {isMenuOpen && (
                                    <a href="/" onClick={closeMenu} className="nav-close"><i className="fal fa-times"></i></a>
                                )}
                            </nav>

                            {/* <div className="header-btn d-none d-xl-block">
                            <Link to="/login" className="login">Login</Link>
                            <Link to="/register" className="btn btn-main-2 btn-sm-2 rounded">Sign up</Link>
                        </div> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderTwo;