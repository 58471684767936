import React from 'react';
import { PageBanner, Modules } from '../layouts/stockmarket/index';
import Layout from '../../common/Layout';


const Stockmarket = () => {
    return (
        <div className="about">
            <Layout>
                <PageBanner
                    title="Stock Market"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="StockMarket"
                />
                <Modules />
            </Layout>
        </div>
    );
}

export default Stockmarket;