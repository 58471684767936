// Layout.js
import React, {useState, useCallback} from 'react';
import { Outlet } from 'react-router-dom'; // For React Router v6
import VisitorInfo from './home01/VisitorInfo';
import FooterOne from '../../common/footer/FooterOne';

const Layout = () => {
  const [chatOpenFunc, setChatOpenFunc] = useState(null);

  const handleOpenChat = useCallback((openChatFunc) => {
    // Logic to trigger the chat open
    setChatOpenFunc(() => openChatFunc);
  }, []);

  return (
    <div>
      {/* Pass handleSetChatOpenFunc to VisitorInfo */}
      <VisitorInfo onOpenChat={handleOpenChat} />
      <Outlet /> {/* This will render the matched child route component */}
      {/* Pass chatOpenFunc to FooterOne */}
      <FooterOne openChat={chatOpenFunc} />
    </div>
  );
};

export default Layout;
