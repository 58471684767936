import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import ContactUsForm from './ContactForm';

class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [
                {
                    id: 1,
                    icon: 'fa fa-envelope',
                    title: 'enquiry.bugndbull@gmail.com',
                    href: 'mailto:enquiry.bugndbull@gmail.com'
                },
                {
                    id: 2,
                    icon: 'fa-solid fa-phone',
                    title: '+918560958895',
                    href: 'tel:+918560958895'
                },
                {
                    id: 3,
                    icon: 'fa fa-map-marker',
                    title: 'Jaipur, Rajasthan 302012',
                },
                {
                    id: 4,
                    icon: 'fa-brands fa-whatsapp', // Change icon to WhatsApp icon
                    title: 'WhatsApp Us',
                    href: 'https://wa.me/+918560958895', // Update with your WhatsApp number
                },

            ]
        }
    }

    // componentDidMount() {
    //     window.scrollTo(0, 0);
    // }

    render() {
        return (
            <section className="contact section-padding">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-4 col-lg-5">
                            <div className="contact-info-wrapper mb-5 mb-lg-0">
                                <h3>Have a specific question?
                                </h3>
                                <span>Fill out the form, and we’ll get back to you as soon as possible.</span>
                                {
                                    this.state.contacts.map((data, i) => (
                                        <a href={data.href}>
                                            <div className="contact-item" key={data.id}>
                                                <i className={data.icon}></i>
                                                <h5>{data.title}</h5>
                                            </div>
                                        </a>
                                    ))
                                }

                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-6">
                            <ContactUsForm formStyle="contact__form form-row " />
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default ContactInfo;
