import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Thanks for your query. We will contact with you soon.</p>
    )
}

function ContactUsForm({ formStyle }) {
    const [result, setResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const sendEmail = (e) => {
        e.preventDefault();
        const { name, email, subject, message } = e.target.elements;
        // Basic email validation
        if (!emailRegex.test(email.value)) {
            setErrorMessage('Please enter a valid email address.');
            return; // Prevent form submission if email is invalid
        }

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_CONTACT_US_TEMPLATE_ID,
                e.target,
                process.env.REACT_APP_EMAILJS_USER_ID
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        setResult(true);
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" id="name" name="fullname" className="form-control" placeholder="Your Name" />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" name="email" id="email" className="form-control" placeholder="Email Address"
                            // Display error message if email is invalid
                            style={{ border: errorMessage ? '1px solid red' : 'none' }}
                            onChange={(e) => setErrorMessage('')} // Clear error on input change
                        />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject" />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="form-group">
                        <textarea id="message" name="message" cols="30" rows="6" className="form-control" placeholder="Your Message"></textarea>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="text-center">
                        <button className="btn btn-main w-100 rounded" type="submit">Send Message</button>
                    </div>
                </div>

                {result ? <div className="form-group"><Result /></div> : null}
            </div>

        </form>
    )
}
export default ContactUsForm;