import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Sample data
const coursesData = {
  cybersecurity: [
    { id: 1, title: 'Introduction to Cybersecurity', description: 'Learn the basics of cybersecurity.' },
    { id: 2, title: 'Advanced Network Security', description: 'Advanced concepts in network security.' },
  ],
  webDevelopment: [
    { id: 3, title: 'HTML & CSS Fundamentals', description: 'Learn the basics of web development with HTML and CSS.' },
    { id: 4, title: 'React for Beginners', description: 'Introduction to React.js for beginners.' },
  ],
  stockMarket: [
    { id: 5, title: 'Stock Market Basics', description: 'Learn how the stock market works.' },
    { id: 6, title: 'Advanced Trading Strategies', description: 'Advanced strategies for stock trading.' },
  ],
};

const DomainList = ({ onSelectDomain }) => (
  <div>
    <h2>Select a Domain</h2>
    <ul className="domain-list">
      {Object.keys(coursesData).map((domain) => (
        <li key={domain} onClick={() => onSelectDomain(domain)}>
          {domain.charAt(0).toUpperCase() + domain.slice(1)}
        </li>
      ))}
    </ul>
  </div>
);

const CourseList = ({ domain, onSelectCourse }) => (
  <div>
    <h2>{domain.charAt(0).toUpperCase() + domain.slice(1)} Courses</h2>
    <ul className="course-list">
      {coursesData[domain].map((course) => (
        <li key={course.id} onClick={() => onSelectCourse(course)}>
          {course.title}
        </li>
      ))}
    </ul>
  </div>
);

const CourseDetails = ({ course }) => (
  <div>
    <h2>{course.title}</h2>
    <p>{course.description}</p>
  </div>
);

const Courses = (domain) => {
  const location = useLocation();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const popupRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.domain) {
      setSelectedDomain(location.state.domain);
    }
  }, [location.state]);

  const handleDomainSelect = (domain) => {
    setSelectedDomain(domain);
    setSelectedCourse(null);
  };

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedDomain(null);
      setSelectedCourse(null);
    }
  };

  useEffect(() => {
    if (selectedDomain || selectedCourse) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedDomain, selectedCourse]);

  return (
    <div>
      <h1>Course Selection</h1>
      {!selectedDomain && <DomainList onSelectDomain={handleDomainSelect} />}
      {(selectedDomain || selectedCourse) && (
        <div className="popup-overlay">
          <div className="popup-content" ref={popupRef}>
            {selectedDomain && !selectedCourse && (
              <>
                <button className="courses-close-button" onClick={() => setSelectedDomain(null)}>
                  x
                </button>
                <CourseList domain={selectedDomain} onSelectCourse={handleCourseSelect} />
              </>
            )}
            {selectedCourse && (
              <>
                <button className="close-button" onClick={() => setSelectedCourse(null)}>
                  x
                </button>
                <CourseDetails course={selectedCourse} />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
