import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: [
                // {
                //     id: 1,
                //     img: 'assets/images/team/team-1.jpg',
                //     title: 'Neeraj Sharma',
                //     text: 'Full Stack Developer',
                //     students: '20',
                //     lessons: '2',
                //     fb: 'fab fa-facebook-f',
                //     twitter: 'fab fa-twitter',
                //     linkedin: 'fab fa-linkedin-in',
                // },
                {
                    id: 1,
                    img: 'assets/images/team/team-3.jpg',
                    title: 'Tanishk Sharma',
                    text: 'Cyber Security',
                    students: '20',
                    lessons: '6',
                    fb: 'fab fa-facebook-f',
                    twitter: 'fab fa-twitter',
                    linkedin: 'fab fa-linkedin-in',
                    linkedinLink: 'https://www.linkedin.com/in/strylee',
                },
                {
                    id: 2,
                    img: 'assets/images/team/team-2.jpg',
                    title: 'Punit Jain',
                    text: 'Option Trader',
                    students: '20',
                    lessons: '4',
                    fb: 'fab fa-facebook-f',
                    twitter: 'fab fa-twitter',
                    linkedin: 'fab fa-linkedin-in',
                    linkedinLink: 'https://www.linkedin.com/in/strylee/',
                },
                {
                    id: 3,
                    img: 'assets/images/team/team-4.jpg',
                    title: 'Ujjwal Natani',
                    text: 'Full Stack Developer',
                    stuendets: '20',
                    lessons: '10',
                    fb: 'fab fa-facebook-f',
                    twitter: 'fab fa-twitter',
                    linkedin: 'fab fa-linkedin-in',
                    linkedinLink: 'https://www.linkedin.com/in/ujjwal-natani/',
                },

            ]
        }
    }

    render() {
        return (
            <section className="instructors section-padding-btm">
                <div className="container">
                    <div className="row">
                        <div className=" pe-5">
                            <div className="section-heading text-center">
                                {/* <span className="subheading">Start Today</span> */}
                                <h2 className="font-lg mb-20">Our Instructors</h2>
                                <p className="mb-30">Our instructors are industry experts and seasoned professionals dedicated to providing you with the best learning experience. Their wealth of knowledge and real-world experience ensures you receive top-tier education and practical insights.</p>

                                {/* <ul className="list-item mb-40">
                                    <li><i className="fa fa-check"></i><h5>Develop your skills Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h5></li>
                                    <li><i className="fa fa-check"></i><h5>Share your knowledge Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h5></li>
                                    <li><i className="fa fa-check"></i><h5>Earn from globally Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h5></li>
                                </ul> */}
                                
                            </div>
                        </div>

                        <div className="">
                            <div className="row masonary-item d-flex justify-content-center">
                                {
                                    this.state.team.map((data, i) => (

                                        <div className="col-lg-4 col-sm-6" key={data.id}>
                                            <div className="team-item team-item-2">
                                                <div className="team-img">
                                                    <img src={data.img} alt="" className="img-fluid" />

                                                    <ul className="team-socials list-inline">
                                                        {/* <li class="list-inline-item"><Link to="#"><i class={data.fb}></i></Link></li>
                                                        <li class="list-inline-item"><Link to="#"><i class={data.twitter}></i></Link></li> */}
                                                        <li class="list-inline-item"><Link to={data.linkedinLink}><i class={data.linkedin}></i></Link></li>
                                                    </ul>
                                                </div>
                                                <div className="team-content">
                                                    <div className="team-info">
                                                        <h4>{data.title}</h4>
                                                        <p>{data.text}</p>
                                                    </div>

                                                    <div className="course-meta">
                                                        <span className="duration"><i className="fas fa-user-alt me-2"></i>{data.students} Students</span>
                                                        <span className="lessons"><i className="far fa-play-circle me-2"></i>{data.lessons} Course</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <a href="contact" className="btn btn-main rounded mb-5" style={{width:'300px'}} >Start Learning today</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        );
    }
}

export default Team;
