import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CourseCat01 extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         category: [
    //             {
    //                 id: 1,
    //                 title: 'Cyber Security',
    //                 img: 'assets/images/icon/icon8.png',
    //                 extraClassName: 'bg-1',
    //             },
    //             {
    //                 id: 2,
    //                 title: 'Stock Market',
    //                 img: 'assets/images/icon/icon7.png',
    //                 extraClassName: 'bg-2',
    //             },
    //             {
    //                 id: 3,
    //                 title: 'Web Development',
    //                 img: 'assets/images/icon/icon6.png',
    //                 extraClassName: 'bg-3',
    //             },
    //             // {
    //             //     id: 4,
    //             //     title: 'Web Development',
    //             //     img :  'assets/images/icon/icon4.png',
    //             //     extraClassName: 'bg-4',
    //             // },
    //             // {
    //             //     id: 5,
    //             //     title: 'Digital Marketing & SEO',
    //             //     img :  'assets/images/icon/icon6.png',
    //             //     extraClassName: 'bg-5',
    //             // },

    //         ]
    //     }
    // }

    render() {
        // Course category

        return (
            <section className="course-category-3 section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-heading text-center">
                                <h2 className="font-lg">Design Your Future With Knowledge</h2>
                                <p>Turn your dreams into reality through learning.</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        {
                             this.state.category.map((data,i)=> (
                                 
                                <div className="col-xl col-lg-4 col-sm-6" key={data.id}>
                                    <div className={`single-course-category style-3 ${data.extraClassName}`}> 
                                        <div className="course-cat-icon">
                                            <img src={data.img} alt="" className="img-fluid"/>
                                        </div>

                                        <div className="course-cat-content">
                                            <h4 className="course-cat-title">
                                                <Link to="#">{data.title}</Link>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                    </div> */}
                    <section class="cards">
                        <article class="card card--1">
                            <Link to='/cyber-security'>
                            <div class="card__img"></div>
                            <a href="#" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                {/* <span class="card__category"> Recipe</span> */}
                                <h3 class="card__title">Cyber Security</h3>
                                <span class="card__by">by <a href="#" class="card__author" title="author">Tanishk Sharma</a></span>
                            </div></Link>
                        </article>


                        <article class="card card--2">
                        <Link to='/stock-market'>
                            <div class="card__img"></div>
                            <a href="#" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                {/* <span class="card__category"> Travel</span> */}
                                <h3 class="card__title">Stock Market</h3>
                                <span class="card__by">by <a href="#" class="card__author" title="author">Punit Jain</a></span>
                            </div>
                            </Link>
                        </article>


                        <article class="card card--3">
                        <Link to='/web-development'>
                            <div class="card__img"></div>
                            <a href="#" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                {/* <span class="card__category"> </span> */}
                                <h3 class="card__title">Web Development</h3>
                                <span class="card__by">by <a href="#" class="card__author" title="author">Ujjwal Natani</a></span>
                            </div>
                            </Link>
                        </article>
                    </section>

                </div>
            </section>

        );
    }
}
export default CourseCat01;