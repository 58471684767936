import React from 'react';
import { PageBanner, Policy } from '../layouts/returnPolicy/index';
import Layout from '../../common/Layout';

const ReturnPolicy = () => {
    return (
        <div>
            <Layout>
                <PageBanner
                    title="Return Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="ReturnPolicy"
                />
                <Policy/>
            </Layout>
        </div>
    );
}

export default ReturnPolicy;