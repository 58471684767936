import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link } from 'react-router-dom';

class Modules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: [
                {
                    id: 1,
                    author: 'Tanishk Sharma',
                    price: 'Rs39998 ',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '80',
                    duration: '20',
                    lessons: '97 ',
                    img: 'assets/images/course/img_06.jpg',
                    authorImg: 'assets/images/course/cybersecurityauthor.jpg',
                    title: ' Comprehensive Cybersecurity Expert ',
                    modules: 'Modules Included: Web Application and API Security, Ethical Hacking and Penetration Testing',
                    desc: ' Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero, culpa. At voluptatem autem ipsam deleniti',
                },
                {
                    id: 2,
                    author: 'Tanishk Sharma',
                    price: 'Rs47996',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '51',
                    duration: '24',
                    lessons: '97 ',
                    img: 'assets/images/course/img_02.jpg',
                    authorImg: 'assets/images/course/cybersecurityauthor.jpg',
                    title: 'Advanced Cybersecurity Specialist',
                    modules: 'Modules Included: Introduction to Cybersecurity, Network Security, Web Application and API Security, Ethical Hacking and Penetration Testing',
                    desc: ' Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero, culpa. At voluptatem autem ipsam deleniti',

                },
                {
                    id: 3,
                    author: 'Tanishk Sharma',
                    price: 'Rs39998',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '41',
                    duration: '12',
                    lessons: '97 ',
                    img: 'assets/images/course/img_03.jpg',
                    authorImg: 'assets/images/course/cybersecurityauthor.jpg',
                    title: ' Elite Cybersecurity Professional',
                    modules: 'Modules Included: Web Application Security and API Security, Introduction to Android Security, Android Application Penetration Testing',
                    desc: ' Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero, culpa. At voluptatem autem ipsam deleniti',

                },
                {
                    id: 4,
                    author: 'Tanishk Sharma',
                    price: 'Rs36999',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '41',
                    duration: '8',
                    lessons: '97 ',
                    img: 'assets/images/course/img_03.jpg',
                    authorImg: 'assets/images/course/cybersecurityauthor.jpg',
                    title: ' Android Security and RAT Development',
                    modules: 'Modules Included: Android Security Course, Windows and Android Advanced RAT',
                    desc: ' Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero, culpa. At voluptatem autem ipsam deleniti',

                }
            ],
            process: [
                {
                    id: 1,
                    step: '01',
                    title: 'Module 1: Introduction to Cybersecurity',
                    duration: '1 week',
                    price: '₹1999',
                    content: [
                        { header: 'Overview of Cybersecurity', details: ['Definition and importance of cybersecurity.', 'Historical evolution of cybersecurity.', 'Current trends and future outlook.'] },
                        { header: 'Types of Cyber Threats', details: ['Malware (viruses, worms, ransomware).', 'Phishing and social engineering attacks.', 'Distributed Denial of Service (DDoS) attacks.'] },
                        { header: 'Basic Terminologies', details: ['Vulnerabilities, threats, and attacks.', 'Risk, mitigation, and defense mechanisms.'] },
                        { header: 'Cybersecurity Frameworks', details: ['Introduction to NIST and ISO/IEC 27001.', 'Overview of CIS Controls.'] },
                        { header: 'Cybersecurity Careers', details: ['Roles and responsibilities in cybersecurity.', 'Certifications and career paths.'] },
                    ],
                },
                {
                    id: 2,
                    step: '02',
                    title: 'Module 2: Network Security',
                    duration: '3 weeks',
                    price: '₹5999',
                    content: [
                        { header: 'Network Fundamentals', details: ['OSI and TCP/IP models.', 'IP addressing and subnetting.', 'Network devices and their functions.'] },
                        { header: 'Secure Network Design', details: ['Network segmentation and zoning.', 'Demilitarized Zones (DMZ).', 'Network Address Translation (NAT).'] },
                        { header: 'Intrusion Detection and Prevention Systems (IDS/IPS)', details: ['Types of IDS/IPS.', 'Signature-based vs. anomaly-based detection.', 'Configuring and managing IDS/IPS.'] },
                        { header: 'Firewalls and VPNs', details: ['Types of firewalls (stateful, stateless, application-layer).', 'Configuring firewall rules and policies.', 'Implementing VPNs (IPSec, SSL/TLS).'] },
                        { header: 'Advanced Network Security', details: ['Deep packet inspection.', 'Network traffic analysis and monitoring.', 'Implementing honeypots and honeynets.'] },
                    ],
                },
                {
                    id: 3,
                    step: '03',
                    title: 'Module 3: Web Application Security and API Security',
                    duration: '8 weeks',
                    price: '₹14999',
                    content: [
                        { header: 'OWASP Top 10', details: ['Detailed analysis of each vulnerability.', 'Real-world examples and case studies.', 'Mitigation strategies.'] },
                        { header: 'Secure Coding Practices', details: ['Input validation and output encoding.', 'Authentication and session management.', 'Secure configuration and error handling.'] },
                        { header: 'Web Security Testing', details: ['Manual vs. automated testing.', 'Tools (Burp Suite, OWASP ZAP).', 'Writing and executing test cases.'] },
                        { header: 'Advanced Web Security Topics', details: ['Server-side request forgery (SSRF).', 'HTTP/2 vulnerabilities.', 'API security best practices.'] },
                        { header: 'Practical Labs', details: ['Exploiting and patching vulnerabilities in a controlled environment.', 'Capture the Flag (CTF) challenges.'] },
                    ],
                },
                {
                    id: 4,
                    step: '04',
                    title: 'Module 4: Ethical Hacking and Penetration Testing',
                    duration: '12 weeks',
                    price: '₹24999',
                    content: [
                        { header: 'Penetration Testing Methodologies', details: ['Planning and reconnaissance.', 'Scanning and enumeration.', 'Exploitation and post-exploitation.'] },
                        { header: 'Vulnerability Assessment Tools', details: ['Nmap, Nessus, and OpenVAS.', 'Metasploit framework.', 'Exploit development and customization.'] },
                        { header: 'Wireless Network Hacking', details: ['Wi-Fi security protocols (WEP, WPA, WPA2).', 'Cracking Wi-Fi passwords.', 'Evil twin attacks and rogue access points.'] },
                        { header: 'Social Engineering Attacks', details: ['Phishing, pretexting, baiting, and tailgating.', 'Psychological manipulation techniques.', 'Defense strategies against social engineering.'] },
                        { header: 'Dark Web and Deep Web Hacking', details: ['Understanding Tor and anonymization techniques.', 'Exploring hidden services.', 'Dark web marketplaces and illegal activities.'] },
                        { header: 'Advanced Exploitation Techniques', details: ['Buffer overflows and shellcode.', 'Privilege escalation.', 'Bypassing antivirus and endpoint protections.'] },
                        { header: 'Reporting and Documentation', details: ['Writing penetration testing reports.', 'Communicating findings to stakeholders.', 'Remediation planning and follow-up.'] },
                    ],
                },
                {
                    id: 5,
                    step: '05',
                    title: 'Module 5: Cryptography',
                    duration: '2 weeks',
                    price: '₹5999',
                    content: [
                        { header: 'Cryptographic Concepts', details: ['Symmetric vs. asymmetric encryption.', 'Hash functions and digital signatures.', 'Key management and exchange.'] },
                        { header: 'Encryption Algorithms', details: ['DES, AES, RSA, and ECC.', 'Practical implementation of encryption.', 'Cryptanalysis techniques.'] },
                        { header: 'Public Key Infrastructure (PKI)', details: ['Certificate authorities and digital certificates.', 'SSL/TLS protocols.', 'Implementing and managing PKI.'] },
                        { header: 'Advanced Cryptography', details: ['Quantum cryptography.', 'Homomorphic encryption.', 'Blockchain and cryptocurrency security.'] },
                        { header: 'Practical Labs', details: ['Implementing encryption in applications.', 'Setting up and managing SSL/TLS.', 'Using cryptographic tools (OpenSSL, GPG).'] },
                    ],
                },
                {
                    id: 6,
                    step: '06',
                    title: 'Module 6: Incident Response and Management',
                    duration: '3 weeks',
                    price: '₹9999',
                    content: [
                        { header: 'Incident Response Planning', details: ['Developing an incident response plan.', 'Roles and responsibilities.', 'Communication and coordination.'] },
                        { header: 'Detection and Analysis', details: ['Identifying indicators of compromise (IOCs).', 'Using SIEM tools for detection.', 'Forensic analysis and evidence collection.'] },
                        { header: 'Containment, Eradication, and Recovery', details: ['Strategies for containment and isolation.', 'Eradication of threats.', 'Recovery and business continuity.'] },
                        { header: 'Advanced Incident Response', details: ['Handling advanced persistent threats (APTs).', 'Insider threat management.', 'Incident response in cloud environments.'] },
                        { header: 'Practical Labs', details: ['Simulating real-world incidents.', 'Conducting forensic analysis.', 'Developing and testing incident response plans.'] },
                    ],
                },
                {
                    id: 7,
                    step: '07',
                    title: 'Module 7: Android Security Course',
                    duration: '4 weeks',
                    price: '₹24999',
                    content: [
                        {
                            subheader: 'Sub Module 1: Introduction to Android Security',
                            subdetails: [{
                                duration: "1 week",
                                content: [{ header: 'Overview of Android Security', details: ['Definition and importance of Android security.', 'Historical evolution of Android security.', 'Current trends and future outlook.'] }, { header: 'Types of Mobile Threats', details: ['Malware (viruses, trojans, ransomware).', 'Phishing and social engineering attacks.', 'Root exploits and privilege escalation.'] }, { header: 'Basic Terminologies', details: ['Vulnerabilities, threats, and attacks.', 'Risk, mitigation, and defense mechanisms.'] }, { header: 'Android Security Frameworks', details: ['Introduction to Android Security Architecture.', 'Overview of Android Security Best Practices.'] }, { header: 'Android Security Careers', details: ['Roles and responsibilities in Android security.', 'Certifications and career paths.'] },]
                            },
                            ]
                        },
                        {
                            subheader: 'Sub Module 2: Android Application Penetration Testing',
                            subdetails: [{
                                duration: "3 week",
                                content: [{ header: 'Penetration Testing Methodologies', details: ['Planning and reconnaissance.', 'Static and dynamic analysis.', 'Exploitation and post-exploitation.'] }, { header: 'Tools and Techniques', details: ['Using tools like Burp Suite, MobSF, and Drozer.', 'Analyzing APK files and decompiling code.', 'Intercepting and analyzing network traffic.'] }, { header: 'Vulnerability Assessment', details: ['Identifying common vulnerabilities (OWASP Mobile Top 10).', 'Testing for insecure data storage, weak server-side controls, etc.','Analyzing permission models and intent handling'] }, { header: 'Exploitation Techniques', details: ['Exploiting vulnerabilities in Android apps.','Gaining root access and privilege escalation.','Bypassing security mechanisms.'] }, { header: 'Practical Labs:', details: ['Setting up a mobile penetration testing environment.','Performing real-world penetration tests on Android apps.','Reporting and documenting findings.'] },
                                ]
                            },
                            ]
                        },
                    ],
                },
                {
                    id: 8,
                    step: '08',
                    title: 'Module 8: Compliance and Risk Management',
                    duration: '3 weeks',
                    price: '₹14999',
                    content: [
                        { header: 'Regulatory Frameworks', details: ['Understanding GDPR, HIPAA, PCI-DSS, and other regulations.', 'Compliance requirements and best practices.', 'Case studies of non-compliance consequences.'] },
                        { header: 'Risk Assessment and Management', details: ['Identifying and assessing risks.', 'Risk mitigation strategies.', 'Developing risk management frameworks.'] },
                        { header: 'Security Audits and Assessments', details: ['Conducting internal and external audits.', 'Gap analysis and remediation.', 'Continuous monitoring and improvement.'] },
                        { header: 'Advanced Risk Management', details: ['Cyber insurance and risk transfer.', 'Third-party risk management.', 'Risk management in cloud environments.'] },
                        { header: 'Practical Labs', details: ['Conducting compliance assessments.', 'Implementing risk mitigation controls.', 'Developing risk management plans.'] },
                    ],
                },
                {
                    id: 9,
                    step: '09',
                    title: 'Module 9: Windows and Android Advanced RAT (Remote Access Trojans) Course',
                    duration: '4 weeks',
                    price: '₹11999',
                    content: [
                        {
                            subheader: 'Sub-Module 1: Introduction to Remote Access Trojans (RATs)',
                            subdetails: [
                                {
                                    duration: '1 week',
                                    content: [
                                        { 
                                            header: 'Research Assignment', 
                                            details: [
                                                'Research the history and evolution of RATs.',
                                                'Identify and present case studies of famous RAT attacks.'
                                            ] 
                                        },
                                        { 
                                            header: 'Hands-On Exercise', 
                                            details: [
                                                'Set up a virtual lab environment using VirtualBox or VMware.',
                                                'Install Windows and Android emulators for testing purposes.'
                                            ] 
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            subheader: 'Sub-Module 2: Windows OS and RAT Development',
                            subdetails: [
                                {
                                    duration: '1 week',
                                    content: [
                                        { 
                                            header: 'Practical Task 1: Basic RAT Development', 
                                            details: [
                                                'Write a simple RAT in C++ or Python that can connect back to a C2 server.',
                                                'Test the RAT in a virtual lab environment.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 2: Persistence Techniques', 
                                            details: [
                                                'Implement registry modification techniques to ensure the RAT persists after a reboot.',
                                                'Test the persistence in a controlled environment.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 3: Keylogging', 
                                            details: [
                                                'Add a keylogging feature to the Windows RAT.',
                                                'Capture keystrokes and send them to the C2 server.'
                                            ] 
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            subheader: 'Sub-Module 3: Android OS and RAT Development',
                            subdetails: [
                                {
                                    duration: '1 week',
                                    content: [
                                        { 
                                            header: 'Practical Task 4: Android RAT Development', 
                                            details: [
                                                'Develop a basic Android RAT using Java or Kotlin.',
                                                'Ensure the RAT can communicate with the C2 server.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 5: Persistence Techniques', 
                                            details: [
                                                'Implement a service that ensures the RAT runs continuously in the background.',
                                                'Test the persistence on an Android emulator.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 6: Screen Capturing', 
                                            details: [
                                                'Add a screen-capturing feature to the Android RAT.',
                                                'Capture and send screenshots to the C2 server.'
                                            ] 
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            subheader: 'Sub-Module 4: Exploitation Techniques',
                            subdetails: [
                                {
                                    duration: '1 week',
                                    content: [
                                        { 
                                            header: 'Practical Task 1: Privilege Escalation', 
                                            details: [
                                                'Exploit a known vulnerability to gain administrative privileges on Windows.',
                                                'Test privilege escalation techniques on an Android device.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 2: Code Injection', 
                                            details: [
                                                'Implement a DLL injection technique on Windows.',
                                                'Test injecting code into a running process.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 3: Root Exploits', 
                                            details: [
                                                'Use a known Android root exploit to gain root access.',
                                                'Test and verify the exploit in a controlled environment.'
                                            ] 
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            subheader: 'Sub-Module 5: Obfuscation and Evasion',
                            subdetails: [
                                {
                                    duration: '1 week',
                                    content: [
                                        { 
                                            header: 'Practical Task 4: Code Obfuscation', 
                                            details: [
                                                'Use obfuscation tools to make the RAT code harder to analyze.',
                                                'Test the obfuscated RAT against antivirus software.'
                                            ] 
                                        },
                                        { 
                                            header: 'Practical Task 5: Anti-Debugging Techniques', 
                                            details: [
                                                'Implement anti-debugging techniques to prevent analysis.',
                                                'Test the effectiveness of these techniques in a lab environment.'
                                            ] 
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            showModules: true,
            activeIndex: null,
            activeIndexInner: {},
            activeIndexSubInner: {},
            hoverModules: false,
            hoverPackages: false,
            windowWidth: window.innerWidth
        }
    }

    handleToggle = () => {
        this.setState({ showModules: !this.state.showModules });
    }

    handleShowModules = () => {
        this.setState({ showModules: true });
    }

    handleShowPackages = () => {
        this.setState({ showModules: false });
    }

    handleHoverModules = (hover) => {
        this.setState({ hoverModules: hover });
    }

    handleHoverPackages = (hover) => {
        this.setState({ hoverPackages: hover });
    }

    handleHover = (index) => {
        this.setState({ activeIndex: index });
    }

    handleClick = (index) => {
        if (this.state.activeIndex === index) {
            this.setState({ activeIndex: null, activeIndexInner: {}, activeIndexSubInner: {} });
        } else {
            this.setState({ activeIndex: index, activeIndexInner: {}, activeIndexSubInner: {} });
        }
    }

    handleClickInner = (outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexInner = { ...prevState.activeIndexInner };
            if (newActiveIndexInner[outerIndex]?.includes(innerIndex)) {
                newActiveIndexInner[outerIndex] = newActiveIndexInner[outerIndex].filter(index => index !== innerIndex);
            } else {
                newActiveIndexInner[outerIndex] = [...(newActiveIndexInner[outerIndex] || []), innerIndex];
            }
            return { activeIndex: outerIndex, activeIndexInner: newActiveIndexInner };
        });
    }

    handleClickInnerSubmodule = (outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexInner = { ...prevState.activeIndexInner };
            // newActiveIndexInner[outerIndex] = [innerIndex];  // Only keep the currently clicked submodule open
            if (newActiveIndexInner[outerIndex] && newActiveIndexInner[outerIndex].includes(innerIndex)) {
                // Close the submodule if it's already open
                newActiveIndexInner[outerIndex] = [];
            } else {
                // Open the submodule and close others
                newActiveIndexInner[outerIndex] = [innerIndex];
            }
            const newActiveIndexSubInner = {};
            return { activeIndex: outerIndex, activeIndexInner: newActiveIndexInner, activeIndexSubInner: newActiveIndexSubInner };
        });
    }

    handleClickSubInner = (secIndex, outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexSubInner = { ...prevState.activeIndexSubInner };
            if (newActiveIndexSubInner[outerIndex]?.includes(innerIndex)) {
                newActiveIndexSubInner[outerIndex] = newActiveIndexSubInner[outerIndex].filter(index => index !== innerIndex);
            } else {
                newActiveIndexSubInner[outerIndex] = [...(newActiveIndexSubInner[outerIndex] || []), innerIndex];
            }
            return { activeIndexSubInner: newActiveIndexSubInner };
        });
    }

    componentDidMount() {
        const listItems = document.querySelectorAll('.rolldown-list li');
        listItems.forEach((item, index) => {
            const delay = `${index / 4}s`;
            item.style.animationDelay = delay;
        });
    }

    componentDidUpdate() {
        const modulesCyberMain = document.querySelector('.modules-cyber-main');
        if (modulesCyberMain) {
            modulesCyberMain.style.height = 'auto';
        }
    }

    render() {
        const { showModules, category, hoverModules, hoverPackages } = this.state;
        const buttonStyle = {
            padding: '10px 20px',
            margin: '0 10px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            transition: 'all 0.3s ease',
        };

        const selectedButtonStyle = {
            ...buttonStyle,
            backgroundColor: '#E0E0E0',
            borderRadius: '50px',
            color: 'black',
        };

        const unselectedButtonStyle = {
            ...buttonStyle,
            backgroundColor: 'transparent',
            color: 'black',
        };

        const hoverStyle = {
            transform: 'translateY(var(--move-hover, 10px))',
            boxShadow: 'var(--shadow-hover, 10px) 10px 20px rgba(0,0,0,0.2)',
        };

        const { windowWidth } = this.state;

        return (
            <section className="course-category-3">
                <div style={{ textAlign: 'center', padding: '20px', paddingTop: '40px' }}>
                    <ButtonGroup variant="text" aria-label="Basic button group">
                        <Button
                            onClick={this.handleShowModules}
                            style={{
                                ...showModules ? selectedButtonStyle : unselectedButtonStyle,
                                ...hoverModules && hoverStyle
                            }}
                            onMouseEnter={() => this.handleHoverModules(true)}
                            onMouseLeave={() => this.handleHoverModules(false)}
                        >
                            Show Modules</Button>
                        <Button
                            onClick={this.handleShowPackages}
                            style={{
                                ...!showModules ? selectedButtonStyle : unselectedButtonStyle,
                                ...hoverPackages && hoverStyle
                            }}
                            onMouseEnter={() => this.handleHoverPackages(true)}
                            onMouseLeave={() => this.handleHoverPackages(false)}
                        >
                            Show Packages </Button>
                    </ButtonGroup>
                    <div style={{ marginTop: '40px' }}>
                        {showModules ? (
                            <div className='modules-cyber-main'>
                                <h2 className='m-3'>Modules</h2>
                                <ul className="rolldown-list" id="myList">
                                    <div className="">
                                        <div className="content-modules">
                                            <div className="row">
                                                {
                                                    this.state.process.map((data, i) => (
                                                        <li key={data.id} className='rolldown-list-li'>
                                                            <div
                                                                className={`step-item-cyber ${this.state.activeIndex === i ? 'active-cyber' : ''}`}
                                                            // onMouseEnter={() => this.handleHover(i)}
                                                            // onMouseLeave={() => this.handleHover(null)}
                                                            >
                                                                <div className="step-text-cyber">
                                                                    <h5><i className={this.state.activeIndex === i ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClick(i)}> {data.title}</i></h5>
                                                                    {this.state.activeIndex === i && (
                                                                        <div className={ `${windowWidth <= 500 ? '' : 'module-details'}` }>
                                                                            <p><strong>Duration:</strong> {data.duration}</p>
                                                                            <p><strong>Price:</strong> {data.price}</p>
                                                                            <div><p><strong>Content:</strong></p>
                                                                                <ul>
                                                                                    {data.content.map((section, secIndex) => (
                                                                                        <li key={secIndex}>
                                                                                            <div
                                                                                                className={`step-item-cyber ${this.state.activeIndexInner[i]?.includes(secIndex) ? 'active-cyber' : ''}`}

                                                                                            >
                                                                                                <div className="step-text-cyber">
                                                                                                    {section.header &&
                                                                                                        <div>
                                                                                                            <h5 className={ `${windowWidth <= 500 ? 'margin-left--120' : ''}` }><i className={this.state.activeIndexInner[i]?.includes(secIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClickInner(i, secIndex)} style={{ fontSize: '17px', marginLeft: '100px' }}> {section.header}</i></h5>
                                                                                                            {this.state.activeIndexInner[i]?.includes(secIndex) && (
                                                                                                                <ul>
                                                                                                                    {section.details.map((detail, detIndex) => (
                                                                                                                        <li type='circle' key={detIndex} className={ `${windowWidth <= 500 ? 'margin-left--8' : 'margin-left-100'}` } style={{ fontSize: '15px' }}><p>{detail}</p></li>
                                                                                                                    ))}
                                                                                                                </ul>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    }
                                                                                                    {section.subheader &&
                                                                                                        <div>
                                                                                                            <h5 className={ `${windowWidth <= 500 ? 'margin-left--120' : ''}` }><i className={this.state.activeIndexInner[i]?.includes(secIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClickInnerSubmodule(i, secIndex)} style={{ fontSize: '17px', marginLeft: '100px' }}> {section.subheader}</i></h5>
                                                                                                            {this.state.activeIndexInner[i]?.includes(secIndex) && (
                                                                                                                <ul>
                                                                                                                    {section.subdetails.map((detail, detIndex) => (
                                                                                                                        <div>
                                                                                                                            <p><strong className={ `${windowWidth <= 500 ? 'margin-left--20' : 'margin-left-100'}` }>Duration:</strong> {detail.duration}</p>
                                                                                                                            <div><p><strong className={ `${windowWidth <= 500 ? 'margin-left--20' : 'margin-left-100'}` }>Content:</strong></p>
                                                                                                                                <ul>
                                                                                                                                    {detail.content.map((content, conIndex) => (
                                                                                                                                        <li key={conIndex}>
                                                                                                                                            <div
                                                                                                                                                className={`step-item-cyber ${this.state.activeIndexSubInner[detIndex]?.includes(conIndex) ? 'active-cyber' : ''}`}
                                                                                                                                            ></div>
                                                                                                                                            <div className="padding-top">
                                                                                                                                                <h5 className={ `${windowWidth <= 500 ? 'margin-left--140' : ''}` }><i className={this.state.activeIndexSubInner[detIndex]?.includes(conIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClickSubInner(secIndex, detIndex, conIndex)} style={{ fontSize: '17px', marginLeft: '100px' }}> {content.header}</i></h5>
                                                                                                                                                {this.state.activeIndexSubInner[detIndex]?.includes(conIndex) && (
                                                                                                                                                    <ul>
                                                                                                                                                        {content.details.map((contentDet, contentDetIndex) => (
                                                                                                                                                            <li type='circle' key={contentDetIndex} className={ `${windowWidth <= 500 ? 'margin-left--8' : 'margin-left-100'}` } style={{ fontSize: '15px'}}><p>{contentDet}</p></li>
                                                                                                                                                        ))}
                                                                                                                                                    </ul>
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                        </li>
                                                                                                                                    ))}
                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    ))}
                                                                                                                </ul>
                                                                                                            )}
                                                                                                        </div>

                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <h2 className='m-5'>Packages</h2>
                                <ul>
                                    <section className="cards course-wrapper" >
                                        <div className="container">
                                            <div className="row justify-content-lg-center" >

                                                {
                                                    this.state.category.map((data, i) => (

                                                        // <div className="col-xl-4 col-lg-4 col-md-6" key={data.id}>
                                                        //     <div className="course-grid bg-shadow tooltip-style">
                                                        //         <div className="course-header">
                                                        //             <div className="course-thumb">
                                                        //                 <img src={data.img} alt="{data.title}" className="img-fluid" />
                                                        //                 <div className="course-price">{data.price}</div>
                                                        //             </div>
                                                        //         </div>

                                                        //         <div className="course-content">
                                                        //             <div className="rating mb-10">
                                                        //                 <i className="fa fa-star"></i>
                                                        //                 <i className="fa fa-star"></i>
                                                        //                 <i className="fa fa-star"></i>
                                                        //                 <i className="fa fa-star"></i>
                                                        //                 <i className="fa fa-star"></i>

                                                        //                 <span>{data.review} ({data.reviewCount} reviews)</span>
                                                        //             </div>

                                                        //             <h3 className="course-title mb-20"> <Link to="#" >{data.title}</Link> </h3>


                                                        //             <div className="course-footer mt-20 d-flex align-items-center justify-content-between ">
                                                        //                 <span className="duration"><i className="far fa-clock me-2"></i>{data.duration} hr</span>
                                                        //                 <span className="students"><i className="far fa-user-alt me-2"></i>{data.students} Students</span>
                                                        //                 <span className="lessons"><i className="far fa-play-circle me-2"></i>{data.lessons} Lessons</span>
                                                        //             </div>
                                                        //         </div>

                                                        //         <div className="course-hover-content">
                                                        //             <div className="price">{data.price}</div>
                                                        //             <h3 className="course-title mb-20 mt-30"> <Link to="#" >{data.title}</Link> </h3>
                                                        //             <div className="course-meta d-flex align-items-center mb-20">
                                                        //                 <div className="author me-4">
                                                        //                     <img src={data.authorImg} alt="{data.title}" className="img-fluid" />
                                                        //                     <Link to="#">{data.author}</Link>
                                                        //                 </div>
                                                        //                 <span className="lesson"> <i className="far fa-file-alt"></i> {data.lessons} lessons</span>
                                                        //             </div>
                                                        //             <p className="mb-20">{data.desc}</p>
                                                        //             <Link to="#" className="btn btn-grey btn-sm rounded">Join Now <i className="fal fa-angle-right"></i></Link>
                                                        //         </div>
                                                        //     </div>
                                                        // </div>
                                                        <div class="cyber-package-card" key={data.id}>
                                                            <div class="cyber-package-content">
                                                                <div class="cyber-package-front">

                                                                    <div class="cyber-package-img">
                                                                        <div class="cyber-package-circle">
                                                                        </div>
                                                                        <div class="cyber-package-circle" id="right">
                                                                        </div>
                                                                        <div class="cyber-package-circle" id="bottom">
                                                                        </div>
                                                                    </div>

                                                                    <div class="cyber-package-front-content" style={{ color: 'black', fontWeight: 'bold' }}>
                                                                        <small class="cyber-package-badge"><span style={{fontSize:'18px'}}>{data.title}</span></small>
                                                                        <div className=" d-flex align-items-center" style={{fontSize:'14px'}}>
                                                                            <div className="author me-4">
                                                                                <img src={data.authorImg} alt={data.title} className="img-fluid" />
                                                                                <Link to="#" >{data.author}</Link>
                                                                            </div>
                                                                            <span className="lesson"> <i className="far fa-file-alt"></i> {data.lessons} lessons</span>
                                                                        </div>
                                                                        <p>{data.modules}</p>
                                                                        <Link to="/contact" className="btn btn-grey btn-sm rounded">Join Now <i className="fal fa-angle-right"></i></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </section>

                                </ul>
                            </div>
                        )}
                    </div>
                </div>

            </section>

        );
    }
}
export default Modules;
