import React from 'react';
import { PageBanner, Modules } from '../layouts/cybersecurity/index';
import Layout from '../../common/Layout';


const Cybersecurity = () => {
    return (
        <div className="about">
            <Layout>
                <PageBanner
                    title="Cyber Security"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="CyberSecurity"
                />
                <Modules />
            </Layout>
        </div>
    );
}

export default Cybersecurity;