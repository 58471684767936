import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CourseGrid01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            selectedCourse: null,
            activeIndex: null,
            activeIndexInner: {},
            activeIndexSubInner: {},
            hoverModules: false,
            hoverPackages: false,
            courses: [
                {
                    id: 1,
                    author: 'Tanishk Sharma',
                    domain: 'CyberSecurity',
                    price: 'Rs36999',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '41',
                    duration: '8',
                    lessons: '97 ',
                    img: 'assets/images/course/cybersecurity.png',
                    authorImg: 'assets/images/course/cybersecurityauthor.jpg',
                    title: ' Android Security And RAT Development',
                    modules: 'Android Security Course, Windows And Android Advanced RAT',
                    desc: 'By the end of this course, students will be able to ethically hack and secure Android and Windows systems. They will master advanced penetration testing and remote access trojans.',
                    process: [
                        {
                            id: 1,
                            step: '01',
                            title: 'Module 1: Android Security Course',
                            duration: '4 weeks',
                            price: '₹24999',
                            content: [
                                {
                                    subheader: 'Sub Module 1: Introduction to Android Security',
                                    subdetails: [{
                                        duration: "1 week",
                                        content: [{ header: 'Overview of Android Security', details: ['Definition and importance of Android security.', 'Historical evolution of Android security.', 'Current trends and future outlook.'] }, { header: 'Types of Mobile Threats', details: ['Malware (viruses, trojans, ransomware).', 'Phishing and social engineering attacks.', 'Root exploits and privilege escalation.'] }, { header: 'Basic Terminologies', details: ['Vulnerabilities, threats, and attacks.', 'Risk, mitigation, and defense mechanisms.'] }, { header: 'Android Security Frameworks', details: ['Introduction to Android Security Architecture.', 'Overview of Android Security Best Practices.'] }, { header: 'Android Security Careers', details: ['Roles and responsibilities in Android security.', 'Certifications and career paths.'] },]
                                    },
                                    ]
                                },
                                {
                                    subheader: 'Sub Module 2: Android Application Penetration Testing',
                                    subdetails: [{
                                        duration: "3 week",
                                        content: [{ header: 'Penetration Testing Methodologies', details: ['Planning and reconnaissance.', 'Static and dynamic analysis.', 'Exploitation and post-exploitation.'] }, { header: 'Tools and Techniques', details: ['Using tools like Burp Suite, MobSF, and Drozer.', 'Analyzing APK files and decompiling code.', 'Intercepting and analyzing network traffic.'] }, { header: 'Vulnerability Assessment', details: ['Identifying common vulnerabilities (OWASP Mobile Top 10).', 'Testing for insecure data storage, weak server-side controls, etc.', 'Analyzing permission models and intent handling'] }, { header: 'Exploitation Techniques', details: ['Exploiting vulnerabilities in Android apps.', 'Gaining root access and privilege escalation.', 'Bypassing security mechanisms.'] }, { header: 'Practical Labs:', details: ['Setting up a mobile penetration testing environment.', 'Performing real-world penetration tests on Android apps.', 'Reporting and documenting findings.'] },
                                        ]
                                    },
                                    ]
                                },
                            ],
                        },
                        {
                            id: 2,
                            step: '02',
                            title: 'Module 2: Windows and Android Advanced RAT (Remote Access Trojans) Course',
                            duration: '4 weeks',
                            price: '₹11999',
                            content: [
                                {
                                    subheader: 'Sub-Module 1: Introduction to Remote Access Trojans (RATs)',
                                    subdetails: [
                                        {
                                            duration: '1 week',
                                            content: [
                                                {
                                                    header: 'Research Assignment',
                                                    details: [
                                                        'Research the history and evolution of RATs.',
                                                        'Identify and present case studies of famous RAT attacks.'
                                                    ]
                                                },
                                                {
                                                    header: 'Hands-On Exercise',
                                                    details: [
                                                        'Set up a virtual lab environment using VirtualBox or VMware.',
                                                        'Install Windows and Android emulators for testing purposes.'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    subheader: 'Sub-Module 2: Windows OS and RAT Development',
                                    subdetails: [
                                        {
                                            duration: '1 week',
                                            content: [
                                                {
                                                    header: 'Practical Task 1: Basic RAT Development',
                                                    details: [
                                                        'Write a simple RAT in C++ or Python that can connect back to a C2 server.',
                                                        'Test the RAT in a virtual lab environment.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 2: Persistence Techniques',
                                                    details: [
                                                        'Implement registry modification techniques to ensure the RAT persists after a reboot.',
                                                        'Test the persistence in a controlled environment.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 3: Keylogging',
                                                    details: [
                                                        'Add a keylogging feature to the Windows RAT.',
                                                        'Capture keystrokes and send them to the C2 server.'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    subheader: 'Sub-Module 3: Android OS and RAT Development',
                                    subdetails: [
                                        {
                                            duration: '1 week',
                                            content: [
                                                {
                                                    header: 'Practical Task 4: Android RAT Development',
                                                    details: [
                                                        'Develop a basic Android RAT using Java or Kotlin.',
                                                        'Ensure the RAT can communicate with the C2 server.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 5: Persistence Techniques',
                                                    details: [
                                                        'Implement a service that ensures the RAT runs continuously in the background.',
                                                        'Test the persistence on an Android emulator.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 6: Screen Capturing',
                                                    details: [
                                                        'Add a screen-capturing feature to the Android RAT.',
                                                        'Capture and send screenshots to the C2 server.'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    subheader: 'Sub-Module 4: Exploitation Techniques',
                                    subdetails: [
                                        {
                                            duration: '1 week',
                                            content: [
                                                {
                                                    header: 'Practical Task 1: Privilege Escalation',
                                                    details: [
                                                        'Exploit a known vulnerability to gain administrative privileges on Windows.',
                                                        'Test privilege escalation techniques on an Android device.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 2: Code Injection',
                                                    details: [
                                                        'Implement a DLL injection technique on Windows.',
                                                        'Test injecting code into a running process.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 3: Root Exploits',
                                                    details: [
                                                        'Use a known Android root exploit to gain root access.',
                                                        'Test and verify the exploit in a controlled environment.'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    subheader: 'Sub-Module 5: Obfuscation and Evasion',
                                    subdetails: [
                                        {
                                            duration: '1 week',
                                            content: [
                                                {
                                                    header: 'Practical Task 4: Code Obfuscation',
                                                    details: [
                                                        'Use obfuscation tools to make the RAT code harder to analyze.',
                                                        'Test the obfuscated RAT against antivirus software.'
                                                    ]
                                                },
                                                {
                                                    header: 'Practical Task 5: Anti-Debugging Techniques',
                                                    details: [
                                                        'Implement anti-debugging techniques to prevent analysis.',
                                                        'Test the effectiveness of these techniques in a lab environment.'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                },
                {
                    id: 2,
                    author: 'Punit Jain',
                    domain: 'StockMarket',
                    price: 'Rs ',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '80',
                    duration: '6.5',
                    lessons: '97 ',
                    img: 'assets/images/course/stockmarket.png',
                    authorImg: 'assets/images/course/webdevauthor.jpg',
                    title: ' Technical Derivative Dynamics',
                    modules: 'Chart Essesntials And Derivatives Dynamics',
                    desc: 'By the end of this course, you will be equipped to make average profitable trades in options trading! Discover advanced strategies to optimize your profits and minimize risks.',
                    process: [
                        {
                            "id": 1,
                            "step": "01",
                            "title": "Module 1: Chart Essentials: Technical Meets SMC",
                            "duration": "5 Weeks",
                            "price": "₹8,999",
                            "content": [
                                { "header": "Technical Analysis", "details": ["Introduction to Technical Analysis", "Basic Charting Techniques", "Technical Indicators", "Advanced Technical Analysis", "Trading Strategies"] },
                                { "header": "Smart Money Concepts", "details": ["Market Structure and Price Action", "Key Smart Money Concepts", "Advanced Smart Money Strategies"] }
                            ]
                        },
                        {
                            "id": 2,
                            "step": "02",
                            "title": "Module 2: Derivatives Dynamics: Mastering Futures & Options",
                            "duration": "8 Weeks",
                            "price": "₹13,999",
                            "content": [
                                { "header": "Introduction to Derivatives", "details": ["Understanding Derivatives: Definition and Types", "The Role of Derivatives in Financial Market"] },
                                { "header": "Futures Contracts", "details": ["Fundamentals of Futures Contracts", "Pricing and Valuation of Futures", "Trading Strategies"] },
                                { "header": "Options Contracts", "details": ["Basics of Options: Calls and Puts", "Options Pricing Models: Black-Scholes and Binomial", "Factors Affecting Option Price (Delta, Gamma, Theta, Vega)", "Key Components of an Option Chain", "Using Tick Charts and Short-Term Time Frames"] },
                                { "header": "Hedging", "details": ["Importance of Hedging in Financial Markets", "Hedging with Futures", "Hedging with Options", "Combining Futures and Options for Hedging"] },
                                { "header": "Essential Skills for Successful Trading", "details": ["Risk Management Strategies", "Mastering Trading Psychology", "Developing a Trading Plan", "Entry and Exit Strategies in Options Trading"] }
                            ]
                        }
                    ],
                },
                {
                    id: 3,
                    author: 'Ujjwal Natani',
                    domain: 'WebDevelopment',
                    price: 'Rs41995',
                    review: '4.5 ',
                    reviewCount: '40',
                    students: '41',
                    duration: '24',
                    lessons: '97 ',
                    img: 'assets/images/course/webdev2.png',
                    authorImg: 'assets/images/course/webdevauthor.jpg',
                    title: ' MERN Stack Developer Package ',
                    modules: 'Web Craft, Frontend Fusion, Backend Synergy, Deployment Excellence ',
                    desc: 'By the end of this course, Become a full-stack developer superstar! Master the MERN stack (MongoDB, Express, React, Node.js) and create powerful, dynamic applications with ease.',
                    process: [
                        {
                            "id": 1,
                            "step": "01",
                            "title": "Module 1: Web Craft: Craft Your Digital Vision with Code and Creativity",
                            "duration": "6 weeks",
                            "price": "₹5999",
                            "content": [
                                { "header": "HTML", "details": ["HTML Basics and Document Structure", "Common HTML Tags", "Attributes and Semantic HTML", "Forms and Tables"] },
                                { "header": "CSS & Advance Styling", "details": ["Basics of CSS and Selectors", "Styling Text and Elements", "The Box Model and Layout", "Advanced Layout Techniques", "CSS Transitions, Transforms and Animations", "CSS Frameworks"] },
                                { "header": "JavaScript Fundamentals", "details": ["Basics of JavaScript", "Functions and Scope", "DOM Manipulation", "JavaScript and Web APIs"] }
                            ]
                        },
                        {
                            "id": 2,
                            "step": "02",
                            "title": "Module 2: Frontend Fusion: Empower Your Frontend",
                            "duration": "7 weeks",
                            "price": "₹13999",
                            "content": [
                                { "header": "React.js Basics", "details": ["Introduction to React", "React State & Props Management", "Event Handling"] },
                                { "header": "Advanced React.js", "details": ["Routing and Forms", "Life Cycle Methods & React Hooks", "State Management With Redux"] },
                                { "header": "Next.js Basics", "details": ["Introduction to NextJS", "Data Fetching in NextJS"] },
                                { "header": "Advanced Next.js", "details": ["API Routes in NextJS", "Authentication and Authorization"] }
                            ]
                        },
                        {
                            "id": 3,
                            "step": "03",
                            "title": "Module 3: Backend Synergy: Master Full Stack Development",
                            "duration": "7 weeks",
                            "price": "₹15999",
                            "content": [
                                { "header": "Relational Database - MariaDB", "details": ["Introduction to MariaDB", "SQL Basics", "Advanced SQL Queries"] },
                                { "header": "Database Design and Optimization", "details": ["Database Design Principles", "Transactions and Concurrency"] },
                                { "header": "Non-Relational Database - MongoDB", "details": ["Introduction to MongoDB", "CRUD Operations in MongoDB", "Indexing and Aggregation", "Data Modeling in MongoDB"] },
                                { "header": "Node.js Basics", "details": ["Introduction to Node.js", "NodeJS Core Modules"] },
                                { "header": "Express.js Framework", "details": ["Introduction to ExpressJS", "Routing and HTTP Methods", "Handling Forms and Data", "Error Handling and Debugging"] },
                            ]
                        },
                        {
                            "id": 4,
                            "step": '04',
                            "title": 'Module 4: Deployment Excellence: Projects Unleashed',
                            "duration": '4 weeks',
                            "price": '₹5999',
                            "content": [
                                {
                                    "subheader": 'Sub Module 1: Deployment',
                                    "subdetails": [{
                                        "duration": "3 week",
                                        "content": [
                                            { "header": "Git and Version Control", "details": ["Introduction to Git", "Branching and Merging", "Remote Repositories", "Collaboration with Git"] },
                                            { "header": "Deployment", "details": ["Introduction to Deployment", "Deploying Web Applications", "Continuous Integration/Continuous Deployment (CI/CD)"] },
                                        ]
                                    },
                                    ]
                                },
                                {
                                    "subheader": 'Sub Module 2: MERN Stack Integration',
                                    "subdetails": [{
                                        "duration": "1 week",
                                        "content": [
                                            { "header": "MERN Stack Integration", "details": ["Introduction to MERN Stack", "Working with MongoDB and Mongoose"] },
                                        ]
                                    },
                                    ]
                                },
                            ],
                        },
                    ],
                }
            ],
            windowWidth: window.innerWidth
        }
    }

    showPopup = (course) => {
        document.documentElement.style.overflow = 'hidden'; // Disable scrolling on both body and html
        document.body.style.overflow = 'hidden';
        this.setState({ popupVisible: true, selectedCourse: course });
    };

    hidePopup = () => {
        document.documentElement.style.overflow = 'auto'; // Re-enable scrolling
        document.body.style.overflow = 'auto';
        this.setState({ popupVisible: false, selectedCourse: null });
        this.setState({ activeIndex: null, activeIndexInner: {}, activeIndexSubInner: {} });
    };

    handleClick = (index) => {
        if (this.state.activeIndex === index) {
            this.setState({ activeIndex: null, activeIndexInner: {}, activeIndexSubInner: {} });
        } else {
            this.setState({ activeIndex: index, activeIndexInner: {}, activeIndexSubInner: {} });
        }
    }

    handleClickInner = (outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexInner = { ...prevState.activeIndexInner };
            if (newActiveIndexInner[outerIndex]?.includes(innerIndex)) {
                newActiveIndexInner[outerIndex] = newActiveIndexInner[outerIndex].filter(index => index !== innerIndex);
            } else {
                newActiveIndexInner[outerIndex] = [...(newActiveIndexInner[outerIndex] || []), innerIndex];
            }
            return { activeIndex: outerIndex, activeIndexInner: newActiveIndexInner };
        });
    }

    handleClickInnerSubmodule = (outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexInner = { ...prevState.activeIndexInner };
            // newActiveIndexInner[outerIndex] = [innerIndex];  // Only keep the currently clicked submodule open
            if (newActiveIndexInner[outerIndex] && newActiveIndexInner[outerIndex].includes(innerIndex)) {
                // Close the submodule if it's already open
                newActiveIndexInner[outerIndex] = [];
            } else {
                // Open the submodule and close others
                newActiveIndexInner[outerIndex] = [innerIndex];
            }
            const newActiveIndexSubInner = {};
            return { activeIndex: outerIndex, activeIndexInner: newActiveIndexInner, activeIndexSubInner: newActiveIndexSubInner };
        });
    }

    handleClickSubInner = (secIndex, outerIndex, innerIndex) => {
        this.setState((prevState) => {
            const newActiveIndexSubInner = { ...prevState.activeIndexSubInner };
            if (newActiveIndexSubInner[outerIndex]?.includes(innerIndex)) {
                newActiveIndexSubInner[outerIndex] = newActiveIndexSubInner[outerIndex].filter(index => index !== innerIndex);
            } else {
                newActiveIndexSubInner[outerIndex] = [...(newActiveIndexSubInner[outerIndex] || []), innerIndex];
            }
            return { activeIndexSubInner: newActiveIndexSubInner };
        });
    }


    updateWindowWidth = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    render() {
        const { popupVisible, selectedCourse, courses } = this.state;

        const { windowWidth } = this.state;

        return (

            //Course style 1
            <section className="course-wrapper section-padding ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-heading mb-50 text-center">
                                <h2 className="font-lg">Trending Courses</h2>
                                <p>Discover Your Perfect Program In Our Courses.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-lg-center">

                        {
                            this.state.courses.map((data, i) => (
                                <div className='col-xl-4 col-lg-4 col-md-6' key={data.id}>
                                    <div className="course-grid-card">
                                        <div class="course-grid-first-content">
                                            <span className='premium-badge'></span>
                                            <div className="course-content">

                                                <h3 className="course-title "> {data.title} </h3>

                                                <div className="rating mb-10 mt-10 ">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>

                                                    <span>{data.review} </span>
                                                </div>

                                                <div className="course-footer d-flex align-items-center justify-content-between" >
                                                    <span className="duration"><i className="far fa-clock me-2"></i>{data.duration} Weeks</span>
                                                    <span>
                                                        <div className="author me-4">
                                                            <img src={data.authorImg} alt="{data.title}" className="img-fluid" />
                                                            {data.author}
                                                        </div>
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="course-grid-second-content">
                                            <p>Modules Included: </p>
                                            <span className="modules ">{data.modules}</span>

                                            <div class="glassy-desc">
                                                <span className="desc">{data.desc}</span>
                                            </div>

                                            <div className="course-footer d-flex" >
                                                <span className="">
                                                    <Link className="btn btn-main rounded footer-btn" onClick={() => this.showPopup(data)}><i class="fa-solid fa-circle-chevron-right pe-2"></i>Module Guide</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>

                {popupVisible && selectedCourse && (
                    <div>
                        <div className="course-popup-overlay" onClick={this.hidePopup}></div>
                        <div className="course-popup-content">
                            <Link className="course-close-btn" onClick={this.hidePopup}>x</Link>
                            <h3 style={{ paddingTop: '10px', paddingBottom:'25px' }}>{selectedCourse.title}</h3>

                            <div className="row align-items-center">
                                <div className={ `${windowWidth <= 500 ? '' : 'width-70'}` } >
                                    <ul className="rolldown-list" id="myList2" >
                                        <h4><strong>Modules:</strong> </h4>
                                        <div className="row">
                                            {
                                                selectedCourse.process.map((data, i) => (
                                                    <li key={data.id} className='rolldown-list-li' style={{ backgroundColor: "#f0f0f0" }}>
                                                        <div
                                                            className={`step-item-cyber ${this.state.activeIndex === i ? 'active-cyber' : ''}`}
                                                        >
                                                            <div className="step-text-cyber">
                                                                <h5><i className={this.state.activeIndex === i ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClick(i)}> {data.title}</i></h5>
                                                                {this.state.activeIndex === i && (
                                                                    <div className={ `${windowWidth <= 500 ? '' : 'module-details'}` }>
                                                                        <p><strong>Duration:</strong> {data.duration}</p>
                                                                        <p><strong>Price:</strong> {data.price}</p>
                                                                        <div><p><strong>Content:</strong></p>
                                                                            <ul>
                                                                                {data.content.map((section, secIndex) => (
                                                                                    <li key={secIndex}>
                                                                                        <div
                                                                                            className={`step-item-cyber ${this.state.activeIndexInner[i]?.includes(secIndex) ? 'active-cyber' : ''}`} style={{ marginLeft:'-20px'}}

                                                                                        >
                                                                                            <div className="step-text-cyber">
                                                                                                {section.header &&
                                                                                                    <div>
                                                                                                        <h5 className={ `${windowWidth <= 500 ? '' : 'margin-left-100'}` }><i className={this.state.activeIndexInner[i]?.includes(secIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right'} onClick={() => this.handleClickInner(i, secIndex)} style={{ fontSize: '17px' }}> {section.header}</i></h5>
                                                                                                        {this.state.activeIndexInner[i]?.includes(secIndex) && (
                                                                                                            <ul>
                                                                                                                {section.details.map((detail, detIndex) => (
                                                                                                                    <li type='circle' key={detIndex} className={ `${windowWidth <= 500 ? 'margin-left-10' : 'margin-left-100'}` } style={{ fontSize: '15px' }}><p>{detail}</p></li>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        )}
                                                                                                    </div>
                                                                                                }
                                                                                                {section.subheader &&
                                                                                                    <div>
                                                                                                        <h5 className={ `${windowWidth <= 500 ? '' : 'margin-left-100'}` }><i className={this.state.activeIndexInner[i]?.includes(secIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right' } onClick={() => this.handleClickInnerSubmodule(i, secIndex)} style={{ fontSize: '17px' }}> {section.subheader}</i></h5>
                                                                                                        {this.state.activeIndexInner[i]?.includes(secIndex) && (
                                                                                                            <ul>
                                                                                                                {section.subdetails.map((detail, detIndex) => (
                                                                                                                    <div>
                                                                                                                        <p><strong className={ `${windowWidth <= 500 ? '' : 'margin-left-100'}` }>Duration:</strong> {detail.duration}</p>
                                                                                                                        <div><p><strong  className={ `${windowWidth <= 500 ? '' : 'margin-left-100'}` }>Content:</strong></p>
                                                                                                                            <ul>
                                                                                                                                {detail.content.map((content, conIndex) => (
                                                                                                                                    <li key={conIndex}>
                                                                                                                                        <div
                                                                                                                                            className={`step-item-cyber ${this.state.activeIndexSubInner[detIndex]?.includes(conIndex) ? 'active-cyber' : ''}`} style={{ marginLeft:'-20px'}}
                                                                                                                                        >
                                                                                                                                        <div className="step-text-cyber">
                                                                                                                                            <h5 className={ `${windowWidth <= 500 ? '' : 'margin-left-100'}` }><i className={this.state.activeIndexSubInner[detIndex]?.includes(conIndex) ? 'fal fa-angle-down' : 'fal fa-angle-right' } onClick={() => this.handleClickSubInner(secIndex, detIndex, conIndex)} style={{ fontSize: '17px' }}> {content.header}</i></h5>
                                                                                                                                            {this.state.activeIndexSubInner[detIndex]?.includes(conIndex) && (
                                                                                                                                                <ul>
                                                                                                                                                    {content.details.map((contentDet, contentDetIndex) => (
                                                                                                                                                        <li type='circle' key={contentDetIndex} className={ `${windowWidth <= 500 ? 'margin-left-10' : 'margin-left-100'}` } style={{ fontSize: '15px'}}><p>{contentDet}</p></li>
                                                                                                                                                    ))}
                                                                                                                                                </ul>
                                                                                                                                            )}
                                                                                                                                        </div></div>
                                                                                                                                    </li>
                                                                                                                                ))}
                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        )}
                                                                                                    </div>

                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                    </ul>
                                </div>

                                {windowWidth <= 500 ? (
                                    null
                                ) : (
                                    <div className="col-xl-5" style={{ width: '25%' }}>
                                        <div className=" mt-3 mt-xl-0">
                                                <img src={selectedCourse.img} alt="" className="img-fluid" style={{ height: '330px', marginTop: '5px' }} />
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="course-footer d-flex " style={{ justifyContent: 'center' }}>
                                <span>
                                    <Link to='/contact' onClick={this.hidePopup} className="btn btn-main rounded " ><i class="fa-solid fa-circle-chevron-right pe-2"></i>Join Now</Link>
                                </span>
                            </div>

                        </div>
                    </div>
                )}

            </section>

        );
    }
}

export default CourseGrid01;