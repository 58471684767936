import React from 'react';
import { PageBanner, Terms } from '../layouts/termsofservice/index';
import Layout from '../../common/Layout';

const TermsOfService = () => {
    return (
        <div>
            <Layout>
                <PageBanner
                    title="Terms Of Service"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="TermsOfService"
                />
                <Terms/>
            </Layout>
        </div>
    );
}

export default TermsOfService;