import React, { useState, useRef, useEffect } from 'react';
import ThreePIcon from '@mui/icons-material/ThreeP';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import emailjs from 'emailjs-com';

const VisitorInfo = ({ onOpenChat }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatContentRef = useRef(null);

  useEffect(() => {
    // Scroll to the end of chat content when chatHistory changes
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    };
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory.length === 0 && isChatOpen) {
      setChatHistory([
        {
          type: 'bot', message: <>
            👋 Welcome to BUGnBULL!<br />
            Hi! How may I assist you?<br />
            What do you want to explore? <br />
            <div className="message-button">
              <button onClick={() => handleCourseClick()}>Courses</button>
              <button onClick={() => handleQueryClick()}>Some Query</button>
            </div>
          </>
        }
      ]);
    }
  }, [chatHistory, isChatOpen]);

  const handleChatOpen = () => {
    setIsChatOpen(true);
  };

  const handleCourseClick = () => {
    // Handle action when user clicks on Courses
    setChatHistory(prevHistory => [...prevHistory,
    { type: 'user', message: 'Courses' },
    {
      type: 'bot', message: <>
        Explore Your Field of Interest<br />
        <div className="message-button" >
          <button>
            <a href="/cyber-security" style={{ color: 'white' }}>Cyber Security</a>
          </button>
          <button>
            <a href="/stock-market" style={{ color: 'white' }}>Stock Market</a>
          </button>
          <button>
            <a href="/web-development" style={{ color: 'white' }}>Web Development</a>
          </button>
        </div>
      </>
    }
    ]);
  };

  const handleQueryClick = () => {
    // Handle action when user clicks on Some Query
    setChatHistory(prevHistory => [...prevHistory,
    { type: 'user', message: 'Some Query' },
    {
      type: 'bot', message: <>
        Let us know, and we'll reach out to you shortly!
        <form action="" onSubmit={handleQuerySend} className='query-form'>
          <input type="text" name='name' placeholder='Your Name' />
          <input type="text" name='mobile_number' placeholder='Your Mobile No.' />
          <textarea style={{ borderRadius: '20px', width: '200px', marginTop: '10px', border: "1px solid #ccc" }} rows={5} name="query" id="" placeholder='Ask Query' ></textarea>
          <button type='submit'>Send</button>
        </form>
      </>
    }
    ]);
  };

  const mobileRegex = /^[6-9][0-9]{9}$/; // Example: Matches a 10-digit mobile number starting with 6, 7, 8, or 9

  const handleQuerySend = (e) => {
    e.preventDefault();
    const { name, mobile_number, query } = e.target.elements;

    if (name.value === '') {
      alert('Please enter valid name.')
      return;
    }

    // Mobile number validation
    if (!mobileRegex.test(mobile_number.value)) {
      alert('Please enter a valid 10-digit mobile number.')
      return;
    }

    if (query === '') {
      alert('Please enter valid query.')
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CHAT_BOT_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then((result) => {
        console.log(result.text);
      },
        (error) => {
          setChatHistory(prevHistory => [...prevHistory,
          { type: 'bot', message: { error } }
          ]);
        }
      );

    setChatHistory(prevHistory => [...prevHistory,
    { type: 'bot', message: 'Thanks for providing your information. We will contact you soon.' }
    ]);

    setTimeout(() => {
      handleChatClose(); // Close the chat after displaying the response message
    }, 3000);
  }

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

  const handleChatReset = () => {
    setChatHistory([]);
    setIsChatOpen(true);
  };

   // Check if onOpenChat is passed as a prop
   useEffect(() => {
    if (onOpenChat) {
      onOpenChat(handleChatOpen);
    }
  }, [onOpenChat]);

  return (
    <div className="visitor-info">
      <button className="chat-button" onClick={handleChatOpen}>
        <ThreePIcon />
      </button>

      {isChatOpen && (
        <div className="chat-container">
          <div className="chat-header">
            <img src="assets/images/chatbot/header.png" alt=""/>
            Helpdesk
            <div onClick={handleChatReset} title='Reset Chat'>
              <RotateLeftIcon style={{ height: '20px' }} className='reset-button' />
            </div>
            <div className="close-button" onClick={handleChatClose} title='Close Helpdesk'>
              x
            </div>
          </div>
          <div className="chat-content" ref={chatContentRef}>
            {chatHistory.map((chat, index) => (
              <div key={index} className={`chat `}>
                {chat.type === "bot" && (
                  <img src="assets/images/chatbot/chat-bot.png" alt="Chat Bot" className="chat-image" />
                )}
                <div className={`chat-message ${chat.type}`}>{chat.message}</div>
              </div>
            ))}
          </div>
          {/* {renderChatContent()} */}
        </div>
      )}
    </div>
  );
};

export default VisitorInfo;




  // const handleChatSubmit = (e) => {
  //   e.preventDefault();
  //   setChatHistory([
  //     ...chatHistory,
  //     { type: 'user', message: formData.message }
  //   ]);
  //   setFormData({ message: '' });
  // };

  // const handleChatSubmit = (e) => {
  //   e.preventDefault();
  //   const currentQuestion = Object.keys(formData)[chatStep];
  //   setChatHistory([
  //     ...chatHistory,
  //     { type: 'user', message: formData[currentQuestion] }
  //   ]);
  //   if (chatStep < questions.length - 1) {
  //     setChatHistory([
  //       ...chatHistory,
  //       { type: 'user', message: formData[currentQuestion] },
  //       { type: 'bot', message: questions[chatStep + 1] }
  //     ]);
  //     setFormData((prevData) => ({ ...prevData }));
  //     setChatStep((prevStep) => prevStep + 1);
  //   } else {
  //     setFormData((prevData) => ({ ...prevData }));
  //     setChatStep((prevStep) => prevStep + 1);
  //     setChatHistory([
  //       ...chatHistory,
  //       { type: 'user', message: formData[currentQuestion] },
  //       { type: 'bot', message: 'Thanks for providing your information. We will contact you soon.' }
  //     ]);
  //     setTimeout(() => {
  //       handleChatClose(); // Close the chat after displaying the response message
  //     }, 4000); // Adjust the timeout duration as needed
  //   }
  // };

  // const renderChatContent = () => {
  //   if (chatStep < questions.length) {
  //     return (
  //       // <form onSubmit={handleChatSubmit} className="chat-form">
  //       //   <input
  //       //     type="text"
  //       //     name={Object.keys(formData)[chatStep]}
  //       //     value={formData[Object.keys(formData)[chatStep]]}
  //       //     onChange={handleInputChange}
  //       //     placeholder="Type here..."
  //       //     autoFocus
  //       //   />
  //       //   <button type="submit">Send</button>
  //       // </form>
  //       <div className="chat-form-container">
  //         <form onSubmit={handleChatSubmit} className="chat-form">
  //           <input
  //             type="text"
  //             name={Object.keys(formData)[chatStep]}
  //             value={formData[Object.keys(formData)[chatStep]]}
  //             onChange={handleInputChange}
  //             placeholder="Type here..."
  //             autoFocus
  //           />
  //           <button type="submit">Send</button>
  //         </form>
  //       </div>
  //     );
  //   } else {
  //     return <button className="submit-button" onClick={handleChatClose}>Close</button>;
  //   }
  // };

  // const renderChatContent = () => {
  //   if (chatStep === 0) {
  //     return (
  //       <div className="chat-form-container">

  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="chat-form-container">
  //         <form onSubmit={handleChatSubmit} className="chat-form">
  //           <input
  //             type="text"
  //             value={formData.message}
  //             onChange={handleInputChange}
  //             placeholder="Type here..."
  //             autoFocus
  //           />
  //           <button type="submit">Send</button>
  //         </form>
  //       </div>
  //     );
  //   }
  // };


  

//   const handleChatSubmit = (e) => {
//     e.preventDefault();
//     // setChatHistory([
//     //   ...chatHistory,
//     //   { type: 'user', message: formData[Object.keys(formData)[chatStep]] }
//     // ]);
//     setChatHistory([
//         ...chatHistory,
//         { type: 'user', message: formData[Object.keys(formData)[chatStep]] },
//         { type: 'bot', message: questions[chatStep + 1] }
//       ]);
//     // setFormData((prevData) => ({ ...prevData, [Object.keys(formData)[chatStep]]: '' }));
//     // if (chatStep < questions.length - 1) {
//     //   setChatStep((prevStep) => prevStep + 1);
//     //   setTimeout(() => {
//     //     setChatHistory([
//     //       ...chatHistory,
//     //       { type: 'bot', message: questions[chatStep + 1] }
//     //     ]);
//     //   }, 500);
//     // } else {
//     //   setTimeout(() => {
//     //     setChatStep((prevStep) => prevStep + 1);
//     //     setChatHistory([
//     //       ...chatHistory,
//     //       { type: 'bot', message: 'Thanks for providing your information. We will contact you soon.' }
//     //     ]);
//     //   }, 500);
//     // }
//     setFormData((prevData) => ({ ...prevData }));
//     setChatStep((prevStep) => prevStep + 1);
//   };








// const [messages, setMessages] = useState([]); // Stores chat history
// const [userInput, setUserInput] = useState(''); // User input field

// // Sample data for specific tutor introductions
// const tutorData = {
//   cybersecurity: "Our cybersecurity tutor, John Doe, has over 10 years of experience in the field...",
//   webDevelopment: "Our web development tutor, Jane Smith, specializes in front-end technologies...",
//   stockMarket: "Our stock market tutor, Alan Brown, is an expert in financial markets and trading..."
// };

// // Mapping for synonyms
// const synonymMapping = {
//   cybersecurity: ['cybersecurity', 'security', 'networking', 'hacking'],
//   webDevelopment: ['web development', 'web dev', 'frontend', 'backend', 'fullstack'],
//   stockMarket: ['stock market', 'trading', 'investing', 'finance']
// };

// // Mapping for page redirections
// const pageRedirectionMapping = {
//   about: ['/about', 'about', 'about you', 'bugnbull'],
//   contact: ['/contact', 'contact', 'contact us', 'get in touch']
// };

// const sendMessage = async (text) => {
//   setMessages([...messages, { user: true, text }]); // Add user message
//   setUserInput(''); // Clear input field

//   const response = await findAnswer(text); // Find matching answer
//   setMessages((prevMessages) => [...prevMessages, response]); // Add chatbot response
// };

// const findAnswer = (text) => {
//   const processedText = processText(text); // Perform NLP (replace with actual NLP logic)

//   // Check for tutor information based on synonyms
//   for (const [key, synonyms] of Object.entries(synonymMapping)) {
//     if (synonyms.some((synonym) => processedText.includes(synonym))) {
//       return { user: false, text: tutorData[key] };
//     }
//   }

//   // Check for page redirections
//   for (const [page, keywords] of Object.entries(pageRedirectionMapping)) {
//     if (keywords.some((keyword) => processedText.includes(keyword))) {
//       window.location.href = pageRedirectionMapping[page][0];
//       return { user: false, text: `Redirecting to the ${page} page...` };
//     }
//   }

//   return { user: false, text: "Sorry, I couldn't find an answer. Can you rephrase your question?" };
// };

// const processText = (text) => {
//   // Basic text processing: lowercase and removing extra spaces
//   return text.toLowerCase().trim();
// };

// const handleKeyPress = (event) => {
//   if (event.key === 'Enter') {
//     sendMessage(userInput);
//   }
// };




// <div className="chat-container">
//   <div className="chat-content">
//     {messages.map((message, index) => (
//       <div key={index} className={`chat-message ${message.user ? 'user' : 'bot'}`}>
//         {message.text}
//       </div>
//     ))}
//   </div>
//   <div className="chat-input">
//     {/* <input
//       value={userInput}
//       onChange={(e) => setUserInput(e.target.value)}
//       onKeyPress={handleKeyPress}
//     /> */}
//     {/* <button onClick={() => sendMessage(userInput)}>Send</button> */}
//     <form onSubmit={handleChatSubmit} className="chat-form">
//       <input
//         type="text"
//         name={Object.keys(formData)[chatStep]}
//         value={userInput}
//         onChange={(e) => setUserInput(e.target.value)}
//         onKeyPress={handleKeyPress}
//         placeholder="Type here..."
//         autoFocus
//       />
//       <button type="submit">Send</button>
//     </form>
//   </div>
// </div>