import React, { Component } from 'react';

class Policy extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            //    Work process Start
            <section className="work-process section-padding">
                <div className="container">
                    <div className="row mb-10">
                        <div className="">
                            <div className="section-heading text-center">
                                <p><strong>Effective Date:</strong> 1st September 2024</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="privacy-doc">

                            <p>Thank you for enrolling in a course with BUGnBULL. We strive to provide high-quality education and want you to be completely satisfied with your learning experience. Please review our return policy below to understand your rights and obligations regarding course cancellations and refunds.</p>

                            <h4>1. Eligibility for Returns and Refunds</h4>
                            <p>To be eligible for a return and refund, you must meet the following criteria:</p>
                            <ul className="disc-list">
                                <li><strong>Course Withdrawal:</strong> The student must submit a written request for withdrawal from the course within 7 days of the course start date.</li>
                                <li><strong>Course Materials:</strong> Any provided course materials must be returned in their original condition.</li>
                                <li><strong>Attendance:</strong> The student must not have attended more than 10% of the total course sessions.</li>
                            </ul>

                            <h4>2. Non-Refundable Items</h4>
                            <p>Certain items are non-refundable, including but not limited to:</p>
                            <ul className="disc-list">
                                <li>Registration or administrative fees, if applicable.</li>
                                <li>Course materials that have been used, damaged, or not returned.</li>
                                <li>Courses that have been completed or for which the student has attended more than 10% of the sessions.</li>
                            </ul>

                            <h4>3. How to Request a Return and Refund</h4>
                            <p>To request a return and refund, please follow these steps:</p>
                            <ul className="disc-list">
                                <li><strong>Submit a Request:</strong> Visit our administration office or contact us at +918560958895 or bugndbull@gmail.com to submit a written request for course withdrawal and refund. Include your name, course details, reason for withdrawal, and proof of enrollment.</li>
                                <li><strong>Review:</strong> We will review your request to determine if it meets the eligibility criteria. We may require additional information or documentation to process your request.</li>
                                <li><strong>Approval:</strong> If your return and refund request is approved, we will notify you within 7-10 business days and provide instructions on returning any course materials if applicable.</li>
                            </ul>

                            <h4>4. Refunds</h4>
                            <ul className="disc-list">
                                <li><strong>Method of Refund:</strong> Approved refunds will be processed via the original payment method or through another mutually agreed method. Please note that it may take additional time for the refund to be processed, depending on the payment method.</li>
                                <li><strong>Refund Amount:</strong> If your return request is approved, you will receive a refund for the course fee minus any non-refundable items (e.g., registration fees, used course materials). If you purchased a course package, the refund amount will be prorated based on the courses attended and returned.</li>
                            </ul>

                            <h4>5. Course Cancellations by the Institute</h4>
                            <p>If we cancel a course for any reason, you will receive a full refund for the affected course, regardless of the amount of content accessed or sessions attended. Alternatively, you may choose to transfer to another course or session if available.</p>

                            <h4>6. Changes to This Return Policy</h4>
                            <p>We reserve the right to update this Return Policy at any time. The updated version will be indicated by an updated “Effective Date” at the top of this page. We encourage you to review this Return Policy periodically to stay informed about our return practices.</p>

                            <h4>7. Contact Us</h4>
                            <p>If you have any questions or concerns about this Return Policy, please contact us at:</p>
                            <p><strong>BugnBull</strong><br />
                                Phone: +918560958895<br />
                                Email: bugndbull@gmail.com<br />
                                {/* Address: [Your Address] */}
                            </p>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Policy;
